import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  private isEmployeeViewSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  // Expose an observable to components for subscribing to changes
  isEmployeeView$: Observable<boolean> =
    this.isEmployeeViewSubject.asObservable();
  isChangedMainDepartment$: Observable<boolean> =
    this.isEmployeeViewSubject.asObservable();
  private departmentNameSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);
  departmentName$: Observable<string> =
    this.departmentNameSubject.asObservable();
  private isChangedMainDepartmentSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private departmentIdsSubject: BehaviorSubject<number[]> = new BehaviorSubject<
    number[]
  >(null);
  departmentIds$: Observable<number[]> =
    this.departmentIdsSubject.asObservable();
  private searchEmployeeNameSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);
  searchEmployeeName$: Observable<string> =
    this.searchEmployeeNameSubject.asObservable();
  private sortSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    '',
  );
  sort$: Observable<string> = this.sortSubject.asObservable();
  private sortBySubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    '',
  );
  sortBy$: Observable<string> = this.sortBySubject.asObservable();
  private dateFromSubject: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    null,
  );
  dateFrom$: Observable<Date> = this.dateFromSubject.asObservable();
  private dateToSubject: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    null,
  );
  dateTo$: Observable<Date> = this.dateToSubject.asObservable();

  updateDepartmentName(departmentName: string): void {
    this.departmentNameSubject.next(departmentName);
  }

  updateIsEmployeeView(isEmployeeView: boolean): void {
    this.isEmployeeViewSubject.next(isEmployeeView);
  }

  updateIsChangedMainDepartment(isChangedMainDepartment: boolean): void {
    this.isChangedMainDepartmentSubject.next(isChangedMainDepartment);
  }

  updateDepartmentIds(departmentIds: number[]): void {
    this.departmentIdsSubject.next(departmentIds);
  }

  updateSort(sort: string): void {
    this.sortSubject.next(sort);
  }

  updateSortBy(sortBy: string): void {
    this.sortBySubject.next(sortBy);
  }

  updateSearchEmployeeName(employeeName: string): void {
    this.searchEmployeeNameSubject.next(employeeName);
  }

  updateDateFrom(dateFrom: Date): void {
    this.dateFromSubject.next(dateFrom);
  }

  updateDateTo(dateTo: Date): void {
    this.dateToSubject.next(dateTo);
  }
}
