import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

export interface IHttpEmployeeList {
  totalItems: number;
  index: number;
  limit: number;
  items: IEmployee[];
  sessionKey: string;
  totalPage: number;
}

export interface IEmployee {
  id: number;
  name: string;
  nameWithTitle: string;
  role: string;
  status: string;
  userId: number;
}

interface IAddNewEmployeePayload {
  email: string;
  firstName: string;
  surName: string;
  middleName: string;
  startDate: string;
  departmentIds: number[];
  isManager: boolean;
  epaIds: number[];
}

@Injectable({
  providedIn: 'root',
})
export class EmployeeHttpService {

  constructor(private http: HttpClient) {
  }

  getEmployeesByDepartment(departmentId: number): Observable<IHttpEmployeeList> {
    return this.http.get<IHttpEmployeeList>(`client/Employee/SearchEmployeeByDepartment/${departmentId}`);
  }

  inviteEmployee(payload: IAddNewEmployeePayload): Observable<any> {
    return this.http.post('client/Employee/Invite', payload);
  }

}
