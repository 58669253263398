import {Injectable} from '@angular/core';
import {FilterOption} from 'src/app/shared/models/filters-list.model';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  showDepartmentsOnly$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isDepartmentChangedSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  isDepartmentChanged$: Observable<boolean> =
    this.isDepartmentChangedSubject.asObservable();
  private isEmployeeCombinationsSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  isEmployeeCombinations$: Observable<boolean> =
    this.isEmployeeCombinationsSubject.asObservable();
  private isDashboardSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  isDashboard$: Observable<boolean> = this.isDashboardSubject.asObservable();
  private departmentIdSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  departmentId$: Observable<number> = this.departmentIdSubject.asObservable();
  private periodOptionIdSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  periodOptionId$: Observable<number> =
    this.periodOptionIdSubject.asObservable();
  private filterPeriodViewSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);
  filterPeriodView$: Observable<string> =
    this.filterPeriodViewSubject.asObservable();
  private isHiddenLoaderSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  isHiddenLoaderSubject$: Observable<boolean> =
    this.isHiddenLoaderSubject.asObservable();
  private viewIdSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  viewId$: Observable<number> = this.viewIdSubject.asObservable();
  private quarterMonthSelectedSubject: BehaviorSubject<FilterOption> =
    new BehaviorSubject<FilterOption>(null);
  quarterMonthSelected$: Observable<FilterOption> = this.quarterMonthSelectedSubject.asObservable();

  updateIsEmployeeCombinations(isEmployeeCombinations: boolean): void {
    this.isEmployeeCombinationsSubject.next(isEmployeeCombinations);
    this.isDashboardSubject.next(false);
  }

  updateIsHiddenLoader(isHiddenLoader: boolean): void {
    this.isHiddenLoaderSubject.next(isHiddenLoader);
  }

  updateIsDashboard(isDashboard: boolean): void {
    this.isDashboardSubject.next(isDashboard);
    this.isEmployeeCombinationsSubject.next(false);
  }

  updateDepartmentId(
    departmentId: number,
    isNotUpdatePeriodOptionId?: boolean,
  ): void {
    if (isNotUpdatePeriodOptionId) {
      return;
    }
    this.departmentIdSubject.next(departmentId);
  }

  updatePeriodOptionId(periodOptionId: number): void {
    this.periodOptionIdSubject.next(periodOptionId);
  }

  updateFilterPeriodView(filterPeriodView: string): void {
    this.filterPeriodViewSubject.next(filterPeriodView);
  }

  updateIsDepartmentChanged(isDepartmentChanged: boolean): void {
    this.isDepartmentChangedSubject.next(isDepartmentChanged);
  }

  showDepartmentsOnly(departmentsOnly: boolean) {
    this.showDepartmentsOnly$.next(departmentsOnly);
  }

  updateViewId(viewId: number): void {
    this.viewIdSubject.next(viewId);
  }

  updateQuarterMonthSelected(quarterMonthSelected: FilterOption): void {
    this.quarterMonthSelectedSubject.next(quarterMonthSelected);
  }
}
