import {Injectable} from '@angular/core';
import {EpaLibraryHttpService} from '@core/https/epa-library-http.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, map} from 'rxjs/operators';
import {
  getBasicEPAInformation,
  getBasicEPAInformationSuccess,
  getChartOverview,
  getChartOverviewSuccess,
  getEmployeeAssignedEPA,
  getEmployeeAssignedEPASuccess,
  getEPAInformation,
  getEPAInformationSuccess,
  getLibraryData,
  getLibraryDataSuccess,
  updateEmployeesAssignedEpa,
  updateEmployeesAssignedEpaSuccess,
} from './epa-library.actions';
import {BasicEPAInformation, ChartOverview, EpaLibraryData} from 'src/app/shared/models/epa-library-data.model';
import {EPAInformation} from 'src/app/shared/models/epa-information.model';
import {EmployeeAssignedEPA} from 'src/app/shared/models/employee-assigned-epa.model';

@Injectable()
export class EpaLibraryEffects {
  getChartOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getChartOverview),
      concatMap(({departmentId}) =>
        this.epaLibraryHttpService
          .getChartOverview(departmentId)
          .pipe(
            map((chartOverview: ChartOverview) =>
              getChartOverviewSuccess({chartOverview}),
            ),
          ),
      ),
    ),
  );

  getLibraryData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLibraryData),
      concatMap(({input}) =>
        this.epaLibraryHttpService
          .getLibraryData(input)
          .pipe(
            map((epaLibraryData: EpaLibraryData) =>
              getLibraryDataSuccess({epaLibraryData}),
            ),
          ),
      ),
    ),
  );

  getBasicEPAInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBasicEPAInformation),
      concatMap(({epaId, preventCashing, dataType}) =>
        this.epaLibraryHttpService
          .getBasicEPAInformation(epaId, preventCashing, dataType)
          .pipe(
            map((basicEPAInformation: BasicEPAInformation) =>
              getBasicEPAInformationSuccess({basicEPAInformation}),
            ),
          ),
      ),
    ),
  );

  getEPAInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEPAInformation),
      concatMap(({epaId, preventCashing, dataType}) =>
        this.epaLibraryHttpService
          .getEPAInformation(epaId, preventCashing, dataType)
          .pipe(
            map((epaInformation: EPAInformation) =>
              getEPAInformationSuccess({epaInformation}),
            ),
          ),
      ),
    ),
  );

  getEmployeeAssignedEPA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEmployeeAssignedEPA),
      concatMap(({input}) => {
        return this.epaLibraryHttpService.getEmployeeAssignedEPA(input).pipe(
          map((data: EmployeeAssignedEPA) => {
            return getEmployeeAssignedEPASuccess({data});
          }),
        );
      }),
    ),
  );

  updateEmployeeAssignedEPA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEmployeesAssignedEpa),
      concatMap(({epaId, employeesUpdate, employeeAssignedEPA}) =>
        this.epaLibraryHttpService
          .updateEmployeesAssignedEpa(epaId, employeesUpdate)
          .pipe(
            map((response: any) => {
              return updateEmployeesAssignedEpaSuccess({
                response,
                employeeAssignedEPA,
              });
            }),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private epaLibraryHttpService: EpaLibraryHttpService,
  ) {
  }
}
